import React, { useState, useEffect } from "react";
import { pythonColor, jsColor, javaColor, cColor, cppColor } from "../Admin/Challenges";
import { SERVER_URL } from "../../consts";

export default function () {
  const [challenges, setChallenges] = useState([]);
  const [attempt, setAttempt] = useState();
  let [history, setHistory] = useState([]);
  useEffect(() => {
    fetch(`${SERVER_URL}/challenges`)
      .then(res => res.json())
      .then(data => {
        setChallenges(data || []);
      })
      .catch(e => console.error("Error is in boyz", e));

    fetch(`${SERVER_URL}/player_challenges`)
      .then(res => res.json())
      .then(data => {
        setHistory(data || []);
      })
      .catch(e => console.error("Error is in boyz", e));
  }, []);

  const toLetters = num => {
    var mod = num % 26,
      pow = (num / 26) | 0,
      out = mod ? String.fromCharCode(96 + mod) : (--pow, "Z");
    return pow ? toLetters(pow) + out : out;
  };

  const emptyPlayerChallenges = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${SERVER_URL}/empty_player_challenges`, requestOptions)
      .then(res => res.json())
      .then(data => {
        setHistory([]);
      })
      .catch(e => console.error("Error is in boyz", e));
  };

  const submit = () => {
    if (!attempt.code || attempt.code.length == 0) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ challenge: attempt }),
    };

    fetch(`${SERVER_URL}/player_challenges`, requestOptions)
      .then(res => res.json())
      .then(data => {
        setHistory([...history, data]);
      })
      .catch(e => console.error("Error is in boyz", e));
    setAttempt();
  };

  const deletePlayerChallenge = challengeId => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: challengeId }),
    };
    fetch(`${SERVER_URL}/player_challenges`, requestOptions)
      .then(res => res.json())
      .then(data => {
        history = history.filter(c => c.id !== challengeId);
        setHistory(history);
        if (attempt && attempt.id === challengeId) {
          setAttempt();
        }
      })
      .catch(e => console.error("Error is in boyz", e));
  };

  function startChallenge(challenge, language) {
    let code = challenge.codeOutlinePython;
    if (language === "javascript") {
      code = challenge.codeOutlineJavaScript;
    } else if (language === "java") {
      code = challenge.codeOutlineJava;
    } else if (language === "c") {
      code = challenge.codeOutlineC;
    } else if (language === "python") {
      code = challenge.codeOutlinePython;
    } else {
      code = challenge.codeOutlineCpp;
    }
    const attempt = {
      challengeInfo: challenge,
      challengeId: challenge.id,
      code,
      language,
    };
    setAttempt(attempt);
  }

  function titleize(title) {
    if (!title) {
      return title;
    } else {
      return title.charAt(0).toUpperCase() + title.substring(1);
    }
  }

  function getBackgroundColor(language) {
    switch (language) {
      case "javascript":
        return jsColor
      case "python":
        return pythonColor
      case "java":
        return javaColor
      case "cpp":
        return cppColor;
      default:
        return cColor
    }
  }

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: 10,
          overflow: "auto",
          flex: 1,
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <h3>Challenges</h3>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {challenges.length === 0 && (
              <div style={{ padding: 10 }}>No challenges</div>
            )}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {challenges.map(challenge => {
                return (
                  <div
                    key={challenge.id}
                    style={{
                      padding: 10,
                      margin: 10,
                      border: "1px grey solid",
                    }}
                  >
                    <h5>{challenge.title || ""}</h5>
                    <h6>{challenge.problemStatement}</h6>

                    <div style={{ display: "flex" }}>
                      <div
                        onClick={() => startChallenge(challenge, "python")}
                        style={{
                          margin: 2,
                          fontWeight: "bold",
                          backgroundColor: pythonColor,
                          padding: "2px 8px",
                          cursor: "pointer",
                        }}
                      >
                        {"Py"}
                      </div>
                      <div
                        onClick={() => startChallenge(challenge, "javascript")}
                        style={{
                          margin: 2,
                          fontWeight: "bold",
                          backgroundColor: jsColor,
                          padding: "2px 8px",
                          cursor: "pointer",
                        }}
                      >
                        {"Js"}
                      </div>
                      {challenge.testInput?.split("\n")?.length ===
                        challenge.inputTypes?.split("\n")?.length && (
                          <div style={{ display: "flex" }}>
                            <div
                              onClick={() => startChallenge(challenge, "java")}
                              style={{
                                margin: 2,
                                fontWeight: "bold",
                                backgroundColor: javaColor,
                                padding: "2px 8px",
                                cursor: "pointer",
                              }}
                            >
                              {"Java"}
                            </div>
                            <div
                              onClick={() => startChallenge(challenge, "c")}
                              style={{
                                margin: 2,
                                fontWeight: "bold",
                                backgroundColor: cColor,
                                padding: "2px 8px",
                                cursor: "pointer",
                              }}
                            >
                              {"C"}
                            </div><div
                              onClick={() => startChallenge(challenge, "cpp")}
                              style={{
                                margin: 2,
                                fontWeight: "bold",
                                backgroundColor: cppColor,
                                padding: "2px 8px",
                                cursor: "pointer",
                              }}
                            >
                              {"Cpp"}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <h3>History</h3>
            <div
              onClick={emptyPlayerChallenges}
              style={{
                marginTop: "auto",
                marginBottom: "auto",
                height: "auto",
                display: "flex",
                alignItems: "center",
                color: "red",
                border: "solid 1px red",
                cursor: "pointer",
              }}
            >
              Delete all
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {history.length === 0 && (
              <div style={{ padding: 10 }}>No history</div>
            )}
            <div
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {history.map(history => {
                return (
                  <div
                    onClick={() => {
                      setAttempt(history);
                    }}
                    key={history.id}
                    style={{
                      padding: 10,
                      margin: 10,
                      border: "1px grey solid",
                      borderColor: history.testPass ? "green" : "red",
                      cursor: "pointer",
                      position: "relative",
                    }}
                  >
                    <div style={{ position: "absolute", top: 2, right: 2 }}>
                      <div
                        style={{
                          color: "red",
                          fontSize: 10,
                        }}
                        onClick={e => {
                          e.stopPropagation();
                          deletePlayerChallenge(history.id);
                        }}
                      >
                        Delete
                      </div>
                    </div>
                    <h5>{history.challengeInfo?.title || ""}</h5>
                    <h6>{history.challengeInfo?.problemStatement || ""}</h6>
                    <div>
                      <div
                        style={{
                          margin: 2,
                          fontWeight: "bold",
                          backgroundColor:
                            getBackgroundColor(history.language),
                          padding: "2px 8px",
                        }}
                      >
                        {history.language}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      {attempt && !attempt.id && (
        <div
          className="attempt"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: 10,
            alignItems: "start",
          }}
        >
          <h2>Play({attempt.language})</h2>
          <h5>{attempt.challengeInfo?.title || ""}</h5>

          <h5>Problem Statement</h5>
          <textarea
            id="problem-statement"
            value={attempt.challengeInfo?.problemStatement || ""}
            rows="10"
            disabled
          ></textarea>

          <h5>Code</h5>
          <textarea
            id="code"
            rows="5"
            value={attempt.code}
            onChange={e => setAttempt({ ...attempt, code: e.target.value })}
          ></textarea>
          <div
            style={{
              padding: 10,
              display: "flex",
              width: "76%",
              justifyContent: "space-between",
            }}
          >
            <button id="submit" onClick={e => submit()}>
              Submit
            </button>
          </div>
        </div>
      )}

      {attempt && attempt.id && (
        <div
          className="attempts"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            padding: 10,
            overflow: "auto",
            alignItems: "start",
          }}
        >
          <h2>Result</h2>
          <h5>{attempt.challengeInfo?.title || ""}</h5>

          <h5>Problem Statement</h5>
          <textarea
            id="problem-statement"
            value={attempt.challengeInfo?.problemStatement || ""}
            rows="10"
            disabled
          ></textarea>

          <h5>Code</h5>
          <textarea
            disabled
            id="code"
            rows="5"
            value={attempt.code}
            onChange={e => setAttempt({ ...attempt, code: e.target.value })}
          ></textarea>
          <div
            style={{
              padding: 10,
              display: "flex",
              width: "76%",
              justifyContent: "space-between",
            }}
          ></div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>Compilation Status</div>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              {attempt.compilationSuccess ? "✔️" : "❌"}
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <div>Ran Successfully</div>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              {attempt.testPass ? "✔️" : "❌"}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Keyword Check: </div>
            <div
              style={{
                marginLeft: 10,
              }}
            >
              {attempt.keywordCheck && attempt.keywordCheck.length == 0 && (
                <div style={{ color: "green" }}>No keywords checked</div>
              )}
              {attempt.keywordCheck &&
                attempt.keywordCheck.length != 0 &&
                attempt.keywordCheck.map(
                  keyword =>
                    keyword ? " ✔️" : " ❌"
                )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Error</div>
            <div
              style={{
                color: "red",
                marginLeft: 10,
              }}
            >
              {attempt.error || "❌"}
            </div>
          </div>
          {attempt.challengeInfo.hiddenTestOutput1 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>Hidden test 1</div>
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                {attempt.hiddenTestPass1 ? "✔️" : "❌"}
              </div>
            </div>
          )}
          {attempt.challengeInfo.hiddenTestOutput2 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>Hidden test 2</div>
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                {attempt.hiddenTestPass2 ? "✔️" : "❌"}
              </div>
            </div>
          )}
          {attempt.challengeInfo.hiddenTestOutput3 && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div>Hidden test 3</div>
              <div
                style={{
                  marginLeft: 10,
                }}
              >
                {attempt.hiddenTestPass3 ? "✔️" : "❌"}
              </div>
            </div>
          )}
          {attempt.challengeInfo.runTests && attempt.testPass && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Test Cases</div>
              <div
                style={{
                  color: "red",
                  marginLeft: 10,
                }}
              >
                {attempt[`testCasesPass${titleize(attempt.language)}`]
                  ? "✔️"
                  : "❌ " + attempt.testError}
              </div>
            </div>
          )}
          {attempt.challengeInfo.runTests && attempt.testPass && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Test Output</div>
              <div
                style={{
                  color: attempt[`testCasesPass${titleize(attempt.language)}`]
                    ? "green"
                    : "red",
                  marginLeft: 10,
                }}
              >
                {attempt.testOutput}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
