import React, { useState, useEffect } from "react";
import { SERVER_URL } from "../../consts";

const defaultChallenge = {
  id: null,
  isActive: true,
  createdAt: null,
  updatedAt: null,
  title: "",
  problemStatement: "",
  challengeDifficulty: 0,
  functionName: "result",
  // template codes
  codeOutlinePython: "",
  codeOutlineJavaScript: "",
  codeOutlineJava: "",
  codeOutlineC: "",
  codeOutlineCpp: "",
  // raw code
  isRaw: false,
  preCodeForRawExecutionPython: "",
  // serializers and deserializers
  serializerPython: "",
  deSerializerPython: "",
  serializerJavaScript: "",
  deSerializerJavaScript: "",
  serializerC: "",
  deSerializerC: "",
  serializerCpp: "",
  deSerializerCpp: "",
  // keyword checks
  keywordCheckPython: null,
  keywordCheckJavaScript: null,
  keywordCheckJava: null,
  keywordCheckC: null,
  keywordCheckCpp: null,
  // test
  testInput: "",
  testOutput: "",
  // hidden tests
  hiddenTestInput1: "",
  hiddenTestOutput1: "",
  hiddenTestInput2: "",
  hiddenTestOutput2: "",
  hiddenTestInput3: "",
  hiddenTestOutput3: "",

  inputTypes: "",
  outputType: "",
  runTests: false,
  testCasesPython: [],
  testCasesC: [],
  testCasesCpp: []
};

const defaultTestCase = {
  language: "",
  name: "",
  targetName: "",
  isClassTest: false,
  runner: "",
  description: "",
  expectedOutput: "",
};

export const pythonColor = "rgba(255,255,145,0.4)";
export const javaColor = "rgba(145,255,255,0.4)";
export const cColor = "rgba(145,145,255,0.4)";
export const jsColor = "rgba(255,145,255,0.4)";
export const cppColor = "rgba(145,145,145,0.4)";

const javaTypes = {
  string: "String",
  integer: "int",
  character: "char",
  float: "float",
  boolean: "boolean",
};

const cReturnTypes = {
  string: "const char*",
  integer: "int",
  character: "char",
  float: "float",
  boolean: "boolean",
};

const cTypes = {
  string: "const char *",
  integer: "int",
  character: "char",
  float: "float",
  boolean: "bool",
};
export default function () {
  let [challenges, setChallenges] = useState([]);

  const [challenge, setChallenge] = useState(defaultChallenge);
  const [selectedTestCase, selectTestCase] = useState();
  const [exportString, setExportString] = useState("");
  useEffect(() => {
    fetch(`${SERVER_URL}/challenges`)
      .then(res => res.json())
      .then(data => {
        setChallenges(data);
      })
      .catch(e => console.error("Error is in boyz", e));
  }, []);

  const toLetters = num => {
    var mod = num % 26,
      pow = (num / 26) | 0,
      out = mod ? String.fromCharCode(96 + mod) : (--pow, "Z");
    return pow ? toLetters(pow) + out : out;
  };

  function generatePythonCodeOutline(args) {
    return `def ${challenge.functionName}(${args}):
        return _ 
      `;
  }
  function generateJavaScriptCodeOutline(args) {
    return `function ${challenge.functionName}(${args}){
        return _;
        }
      `;
  }

  function generateJavaCodeOutline(params, paramTypes) {
    const args = params
      .map((param, index) => {
        return `${javaTypes[paramTypes[index]]} ${toLetters(index + 1)}`;
      })
      .join(",");

    return `public static ${javaTypes[challenge.outputType]} ${challenge.functionName
      }(${args}){

    }
`;
  }

  function generateCCodeOutline(params, paramTypes) {
    const args = params
      .map((param, index) => {
        return `${cTypes[paramTypes[index]]} ${toLetters(index + 1)}`;
      })
      .join(",");

    return `${cReturnTypes[challenge.outputType]} ${challenge.functionName
      }(${args}){

    }
`;
  }



  function generateCppCodeOutline(params, paramTypes) {
    const args = params
      .map((param, index) => {
        return `${cTypes[paramTypes[index]]} ${toLetters(index + 1)}`;
      })
      .join(",");

    return `${cReturnTypes[challenge.outputType]} ${challenge.functionName
      }(${args}){

    }
`;
  }
  const createChallenge = () => {
    if (!challenge.testInput) {
      return;
    }
    const params = challenge.testInput.split("\n");
    const paramTypes = challenge.inputTypes.split("\n");
    if (params.length != paramTypes.length) {
      alert("Invalid input and its types");
      return;
    }
    const args = params
      .map((param, index) => {
        return toLetters(index + 1);
      })
      .join(",");

    setChallenge({
      ...challenge,
      codeOutlinePython: generatePythonCodeOutline(args),
      codeOutlineJavaScript: generateJavaScriptCodeOutline(args),
      codeOutlineJava: generateJavaCodeOutline(params, paramTypes),
      codeOutlineC: generateCCodeOutline(params, paramTypes),
      codeOutlineCpp: generateCppCodeOutline(params, paramTypes),
    });
  };

  const deleteChallenge = challengeId => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ id: challengeId }),
    };
    fetch(`${SERVER_URL}/challenges`, requestOptions)
      .then(res => res.json())
      .then(data => {
        challenges = challenges.filter(c => c.id !== challengeId);
        setChallenges(challenges);
        if (challenge.id === challengeId) {
          setChallenge(defaultChallenge);
        }
      })
      .catch(e => console.error("Error is in boyz", e));
  };

  const emptyChallenges = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    fetch(`${SERVER_URL}/empty_challenges`, requestOptions)
      .then(res => res.json())
      .then(data => {
        setChallenges([]);
      })
      .catch(e => console.error("Error is in boyz", e));
  };

  const submit = () => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ challenge }),
    };

    if (challenge.id) {
      fetch(`${SERVER_URL}/challenges`, requestOptions)
        .then(res => res.json())
        .then(data => {
          for (let i = 0; i < challenges.length; i++) {
            if (challenges[i].id === challenge.id) {
              challenges[i] = challenge;
              break;
            }
          }
          setChallenges(challenges);
        })
        .catch(e => console.error("Error is in boyz", e));
    } else {
      fetch(`${SERVER_URL}/challenges`, requestOptions)
        .then(res => res.json())
        .then(data => {
          setChallenges([...challenges, data]);
        })
        .catch(e => console.error("Error is in boyz", e));
    }
    setChallenge({ ...defaultChallenge });
  };

  function handleInput(e) {
    setChallenge({ ...challenge, [e.target.name]: e.target.value });
  }

  function getExportQueries() {
    if (exportString) {
      const exportTextElement = document.getElementById("export_text");
      exportTextElement.select();
      document.execCommand("copy");
      setExportString("");
      alert("Copied");
    } else {
      fetch(`${SERVER_URL}/export_challenges`)
        .then(res => res.json())
        .then(data => {
          setExportString(data.join("\n\n"));
        })
        .catch(e => console.error("Error in exporting", e));
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <h3>Challenges</h3>
        <div
          onClick={emptyChallenges}
          style={{
            marginTop: "auto",
            marginBottom: "auto",
            height: "auto",
            display: "flex",
            alignItems: "center",
            color: "red",
            border: "solid 1px red",
            cursor: "pointer",
          }}
        >
          Delete all
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {challenges.length === 0 && (
          <div style={{ padding: 10 }}>No challenges</div>
        )}
        <div
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          {challenges.map(challenge => {
            return (
              <div
                key={challenge.id}
                style={{
                  padding: 10,
                  margin: 10,
                  border: "1px grey solid",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => {
                  setChallenge(challenge);
                }}
              >
                {" "}
                <h5>{challenge?.title || ""}</h5>
                <h6>{challenge.problemStatement}</h6>
                <div style={{ position: "absolute", top: 2, right: 2 }}>
                  <div
                    style={{
                      color: "red",
                      fontSize: 10,
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      deleteChallenge(challenge.id);
                    }}
                  >
                    Delete
                  </div>
                </div>
              </div>
            );
          })}
          <div style={{ padding: 8, display: "flex", flexDirection: "column" }}>
            <input
              type="button"
              onClick={getExportQueries}
              value={exportString ? "Copy" : "Export"}
            />
            <div style={{ padding: 8 }}></div>

            {exportString && (
              <textarea
                name="export"
                id="export_text"
                cols="80"
                rows="20"
                value={exportString}
              ></textarea>
            )}
          </div>
        </div>
        <div
          className="App"
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            alignItems: "flex-start",
          }}
        >
          <h2>{challenge.id ? "Update challenge" : "Create New Challenge"}</h2>
          <h5>Problem Statement</h5>
          <textarea
            id="problem-statement"
            value={challenge.problemStatement}
            rows="10"
            name="problemStatement"
            onChange={handleInput}
          ></textarea>
          <h5>Title</h5>
          <textarea
            id="title"
            value={challenge.title}
            rows="2"
            name="title"
            onChange={handleInput}
          ></textarea>
          <h5>Input Types (Required for java and C)</h5>
          <div style={{ fontSize: 12 }}>
            Supports exactly: integer,string,character,boolean and float
          </div>
          <textarea
            id="input-types"
            rows="5"
            value={challenge.inputTypes || ""}
            name="inputTypes"
            onChange={handleInput}
          ></textarea>
          <h5>Input</h5>
          <textarea
            id="test-input"
            rows="5"
            value={challenge.testInput || ""}
            name="testInput"
            onChange={handleInput}
          ></textarea>
          <h5>Output Type (required for java and C)</h5>
          <div style={{ fontSize: 12 }}>
            Supports exactly: integer,string,character,boolean and float
          </div>
          <textarea
            id="output-type"
            rows="5"
            name="outputType"
            value={challenge.outputType || ""}
            onChange={handleInput}
          ></textarea>
          <h5>Output</h5>
          <textarea
            id="test-output"
            rows="5"
            name="testOutput"
            value={challenge.testOutput || ""}
            onChange={handleInput}
          ></textarea>

          <div style={{ display: "flex", flexDirection: "column" }}>
            <h5>Hidden Test Input 1</h5>
            <textarea
              id="hidden-test-input1"
              rows="5"
              value={challenge.hiddenTestInput1 || ""}
              name="hiddenTestInput1"
              onChange={handleInput}
            ></textarea>
            <h5>Hidden Test Output 1</h5>
            <textarea
              id="hidden-test-output1"
              rows="5"
              name="hiddenTestOutput1"
              value={challenge.hiddenTestOutput1 || ""}
              onChange={handleInput}
            ></textarea>
            <h5>Hidden Test Input 2</h5>
            <textarea
              id="hidden-test-input2"
              rows="5"
              value={challenge.hiddenTestInput2 || ""}
              name="hiddenTestInput2"
              onChange={handleInput}
            ></textarea>
            <h5>Hidden Test Output 2</h5>
            <textarea
              id="hidden-test-output2"
              rows="5"
              name="hiddenTestOutput2"
              value={challenge.hiddenTestOutput2 || ""}
              onChange={handleInput}
            ></textarea>{" "}
            <h5>Hidden Test Input 3</h5>
            <textarea
              id="hidden-test-input3"
              rows="5"
              value={challenge.hiddenTestInput3 || ""}
              name="hiddenTestInput3"
              onChange={handleInput}
            ></textarea>
            <h5>Hidden Test Output 3</h5>
            <textarea
              id="hidden-test-output3"
              rows="5"
              name="hiddenTestOutput3"
              value={challenge.hiddenTestOutput3 || ""}
              onChange={handleInput}
            ></textarea>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={challenge.isRaw}
              onChange={e => {
                setChallenge({ ...challenge, isRaw: e.target.checked });
              }}
            />
            <div>Is Raw Code</div>
          </div>
          {!challenge.isRaw && (
            <>
              <h5>Function Name (Must match the function name in code)</h5>
              <textarea
                id="function-name"
                name="functionName"
                value={challenge.functionName}
                onChange={handleInput}
              ></textarea>
            </>
          )}
          <h5>Select Difficulty</h5>
          <select
            name="challengeDifficulty"
            value={challenge.challengeDifficulty}
            onChange={handleInput}
          >
            <option value={0}>Easy</option>
            <option value={1}>Medium</option>
            <option value={2}>Hard</option>
          </select>

          <div style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={challenge.runTests}
              onChange={e => {
                setChallenge({ ...challenge, runTests: e.target.checked });
              }}
            />
            <div>Run Test Cases</div>
          </div>
          {!challenge.isRaw && (
            <button
              id="create-challenge"
              onClick={e => createChallenge()}
              style={{ marginTop: 12 }}
            >
              Create Challenge
            </button>
          )}

          <div
            style={{
              width: "80%",
              borderRadius: 12,
              backgroundColor: pythonColor,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: 8,
              padding: 8,
              marginRight: 20,
            }}
          >
            <h5>Python Code Outline</h5>
            <textarea
              id="python-code"
              rows="5"
              name="codeOutlinePython"
              value={challenge.codeOutlinePython}
              onChange={handleInput}
            ></textarea>
            <h5>
              Python Keyword(For multiple keywords separate the keywords with a
              comma)
            </h5>
            <textarea
              id="keyword-python"
              name="keywordCheckPython"
              value={challenge.keywordCheckPython || ""}
              onChange={handleInput}
            ></textarea>
            {challenge.runTests && (
              <div
                style={{
                  color: "black",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  margin: "12px",
                  alignItems: "flex-start",
                }}
              >
                {challenge.testCasesPython && (
                  <div>
                    {challenge.testCasesPython.map((test, index) => {
                      return (
                        <PythonTestCaseCard
                          key={index}
                          testCase={test}
                          startEdit={selectTestCase}
                        />
                      );
                    })}
                  </div>
                )}
                <button
                  onClick={e => {
                    if (
                      !selectedTestCase ||
                      selectedTestCase.language != "python"
                    ) {
                      selectTestCase({
                        ...defaultTestCase,
                        language: "python",
                      });
                    }
                  }}
                >
                  Add Test +
                </button>
                {selectedTestCase && selectedTestCase.language === "python" && (
                  <PythonTestCaseCard
                    testCase={selectedTestCase}
                    editing={true}
                    setTestCase={testCase => {
                      if (!testCase?.name?.startsWith("test_")) {
                        alert("Invaid test case name");
                        return;
                      }
                      challenge.testCasesPython =
                        challenge.testCasesPython || [];
                      const updated =
                        challenge.testCasesPython.filter((tCase, index) => {
                          if (tCase.name === testCase.name) {
                            challenge.testCasesPython[index] = { ...testCase };
                            return true;
                          }
                          return false;
                        }).length !== 0;
                      if (!updated) {
                        challenge.testCasesPython.push(testCase);
                      }
                      setChallenge({ ...challenge });
                      selectTestCase();
                    }}
                  ></PythonTestCaseCard>
                )}
              </div>
            )}
          </div>
          <div
            style={{
              width: "80%",
              backgroundColor: jsColor,
              borderRadius: 12,
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginTop: 8,
              marginRight: 20,
              padding: 8,
            }}
          >
            <h5>Javascript Code Outline</h5>
            <textarea
              id="javascript-code"
              name="codeOutlineJavaScript"
              rows="5"
              value={challenge.codeOutlineJavaScript}
              onChange={handleInput}
            ></textarea>
            <h5>JavaScript Keyword</h5>
            <textarea
              id="keyword-javascript"
              name="keywordCheckJavaScript"
              value={challenge.keywordCheckJavaScript || ""}
              onChange={handleInput}
            ></textarea>
          </div>
          {challenge.inputTypes?.split("\n").length ===
            challenge.testInput?.split("\n").length && (
              <>
                <div
                  style={{
                    width: "80%",
                    borderRadius: 12,
                    backgroundColor: javaColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: 8,
                    padding: 8,
                    marginRight: 20,
                  }}
                >
                  <h5>Java Code Outline</h5>
                  <textarea
                    id="java-code"
                    rows="5"
                    name="codeOutlineJava"
                    value={challenge.codeOutlineJava}
                    onChange={handleInput}
                  ></textarea>
                  <h5>
                    Java Keyword(For multiple keywords separate the keywords with
                    a comma)
                  </h5>
                  <textarea
                    id="keyword-java"
                    name="keywordCheckJava"
                    value={challenge.keywordCheckJava || ""}
                    onChange={handleInput}
                  ></textarea>
                  {challenge.runTests && (
                    <div
                      style={{
                        color: "black",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        margin: "12px",
                        alignItems: "flex-start",
                      }}
                    >
                      {challenge.testCasesJava && (
                        <div>
                          {challenge.testCasesJava.map((test, index) => {
                            return (
                              <JavaTestCaseCard
                                key={index}
                                testCase={test}
                                startEdit={selectTestCase}
                              />
                            );
                          })}
                        </div>
                      )}
                      <button
                        onClick={e => {
                          if (
                            !selectedTestCase ||
                            selectedTestCase.language != "java"
                          ) {
                            selectTestCase({
                              ...defaultTestCase,
                              language: "java",
                            });
                          }
                        }}
                      >
                        Add Test +
                      </button>
                      {selectedTestCase && selectedTestCase.language === "java" && (
                        <JavaTestCaseCard
                          testCase={selectedTestCase}
                          editing={true}
                          setTestCase={testCase => {
                            challenge.testCasesJava =
                              challenge.testCasesJava || [];
                            const updated =
                              challenge.testCasesJava.filter((tCase, index) => {
                                if (tCase.name === testCase.name) {
                                  challenge.testCasesJava[index] = {
                                    ...testCase,
                                  };
                                  return true;
                                }
                                return false;
                              }).length !== 0;
                            if (!updated) {
                              challenge.testCasesJava.push(testCase);
                            }
                            setChallenge({ ...challenge });
                            selectTestCase();
                          }}
                        ></JavaTestCaseCard>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "80%",
                    borderRadius: 12,
                    backgroundColor: cColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: 8,
                    padding: 8,
                    marginRight: 20,
                  }}
                >
                  <h5>C Code Outline</h5>
                  <textarea
                    id="c-code"
                    rows="5"
                    name="codeOutlineC"
                    value={challenge.codeOutlineC}
                    onChange={handleInput}
                  ></textarea>
                  <h5>
                    C Keyword(For multiple keywords separate the keywords with a
                    comma)
                  </h5>
                  <textarea
                    id="keyword-c"
                    name="keywordCheckC"
                    value={challenge.keywordCheckC || ""}
                    onChange={handleInput}
                  ></textarea>

                  {challenge.runTests && (
                    <div
                      style={{
                        color: "black",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        margin: "12px",
                        alignItems: "flex-start",
                      }}
                    >
                      {challenge.testCasesC && (
                        <div>
                          {challenge.testCasesC.map((test, index) => {
                            return (
                              <CTestCaseCard
                                key={index}
                                testCase={test}
                                startEdit={selectTestCase}
                              />
                            );
                          })}
                        </div>
                      )}
                      <button
                        onClick={e => {
                          if (
                            !selectedTestCase ||
                            selectedTestCase.language != "c"
                          ) {
                            selectTestCase({
                              ...defaultTestCase,
                              language: "c",
                            });
                          }
                        }}
                      >
                        Add Test +
                      </button>
                      {selectedTestCase && selectedTestCase.language === "c" && (
                        <CTestCaseCard
                          testCase={selectedTestCase}
                          editing={true}
                          setTestCase={testCase => {
                            challenge.testCasesC = challenge.testCasesC || [];
                            const updated =
                              challenge.testCasesC.filter((tCase, index) => {
                                if (tCase.name === testCase.name) {
                                  challenge.testCasesC[index] = {
                                    ...testCase,
                                  };
                                  return true;
                                }
                                return false;
                              }).length !== 0;
                            if (!updated) {
                              challenge.testCasesC.push(testCase);
                            }
                            setChallenge({ ...challenge });
                            selectTestCase();
                          }}
                        ></CTestCaseCard>
                      )}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "80%",
                    borderRadius: 12,
                    backgroundColor: cppColor,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    marginTop: 8,
                    padding: 8,
                    marginRight: 20,
                  }}
                >
                  <h5>Cpp Code Outline</h5>
                  <textarea
                    id="cpp-code"
                    rows="5"
                    name="codeOutlineCpp"
                    value={challenge.codeOutlineCpp}
                    onChange={handleInput}
                  ></textarea>
                  <h5>
                    Cpp Keyword(For multiple keywords separate the keywords with a
                    comma)
                  </h5>
                  <textarea
                    id="keyword-cpp"
                    name="keywordCheckCpp"
                    value={challenge.keywordCheckCpp || ""}
                    onChange={handleInput}
                  ></textarea>


                  {challenge.runTests && (
                    <div
                      style={{
                        color: "black",
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        margin: "12px",
                        alignItems: "flex-start",
                      }}
                    >
                      {challenge.testCasesCpp && (
                        <div>
                          {challenge.testCasesCpp.map((test, index) => {
                            return (
                              <CppTestCaseCard
                                key={index}
                                testCase={test}
                                startEdit={selectTestCase}
                              />
                            );
                          })}
                        </div>
                      )}
                      <button
                        onClick={e => {
                          if (
                            !selectedTestCase ||
                            selectedTestCase.language != "cpp"
                          ) {
                            selectTestCase({
                              ...defaultTestCase,
                              language: "cpp",
                            });
                          }
                        }}
                      >
                        Add Test +
                      </button>
                      {selectedTestCase && selectedTestCase.language === "cpp" && (
                        <CppTestCaseCard
                          testCase={selectedTestCase}
                          editing={true}
                          setTestCase={testCase => {
                            challenge.testCasesCpp = challenge.testCasesCpp || [];
                            const updated =
                              challenge.testCasesCpp.filter((tCase, index) => {
                                if (tCase.name === testCase.name) {
                                  challenge.testCasesCpp[index] = {
                                    ...testCase,
                                  };
                                  return true;
                                }
                                return false;
                              }).length !== 0;
                            if (!updated) {
                              challenge.testCasesCpp.push(testCase);
                            }
                            setChallenge({ ...challenge });
                            selectTestCase();
                          }}
                        ></CppTestCaseCard>
                      )}
                    </div>
                  )}
                </div>
              </>
            )}
          <div
            style={{
              padding: 15,
              display: "flex",
              width: "74%",
              justifyContent: "space-between",
            }}
          >
            <button id="submit" onClick={e => submit()}>
              {challenge.id ? "Update" : "Submit"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function PythonTestCaseCard({ testCase, startEdit, editing, setTestCase }) {
  const [test, setTest] = useState(testCase);

  function handleInput(e) {
    setTest({ ...test, [e.target.name]: e.target.value });
  }
  if (testCase.language != "python") {
    return <div></div>;
  }
  return (
    <div
      onClick={() => {
        if (startEdit) startEdit(testCase);
      }}
      style={{
        padding: 4,
        margin: 8,
        borderRadius: 12,
        border: "solid 1px black",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <h6> Test case name ({testCase.language})</h6>
      <div style={{ textAlign: "start", fontSize: 12 }}>
        this should start with test_ and should be a unique value. For example:
        test_Class1, test_Class1Types{" "}
      </div>
      <textarea
        id="name"
        disabled={!editing || testCase.name}
        value={test.name}
        rows="1"
        name="name"
        onChange={handleInput}
      />
      <h6>Target Name (Function/Class to test)</h6>

      <textarea
        id="target"
        disabled={!editing}
        value={test.targetName}
        rows="1"
        name="targetName"
        onChange={handleInput}
      />
      <h6>Test case description</h6>
      <textarea
        id="description"
        disabled={!editing}
        value={test.description}
        rows="1"
        name="description"
        onChange={handleInput}
      />
      <h6>Test case expected outcome </h6>
      <textarea
        id="expectedOutput"
        disabled={!editing}
        value={test.expectedOutput}
        rows="1"
        name="expectedOutput"
        onChange={handleInput}
      />
      <div style={{ display: "flex", alignItems: "center" }}>
        <input
          type="checkbox"
          disabled={!editing}
          checked={test.isClassTest}
          onChange={e => {
            setTest({ ...test, isClassTest: e.target.checked });
          }}
        />
        <div>This is class test</div>
      </div>
      {editing && (
        <div style={{ fontSize: 12 }}>
          {test.isClassTest && (
            <>
              For class methods test, just write the assertions, test function
              and setup is done automatically. Any indentation/spelling mistakes
              here will result in the test to fail. Here mocker and capsys
              object are available. And specified class method is patched
              already.
            </>
          )}
          {!test.isClassTest && (
            <>
              For functions test, write a test function with the above test case
              name. The function name should start with "test". To check if the
              function was called, __stats__ dict is available here.
              __stats__["functionName"]["call_count"] will return the number of
              times the function was invoked during the program execution
            </>
          )}
        </div>
      )}
      {test.isClassTest && (
        <>
          <h6>Function to test</h6>
          <textarea
            id="functionName"
            disabled={!editing}
            value={test.functionName}
            rows="1"
            name="functionName"
            onChange={handleInput}
          />
        </>
      )}
      <textarea
        id="test-runner"
        value={test.runner}
        disabled={!editing}
        rows="10"
        name="runner"
        onChange={handleInput}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {editing && <button onClick={() => setTestCase(test)}>Done</button>}
      </div>
    </div>
  );
}

function JavaTestCaseCard({ testCase, startEdit, editing, setTestCase }) {
  const [test, setTest] = useState(testCase);

  function handleInput(e) {
    setTest({ ...test, [e.target.name]: e.target.value });
  }

  if (testCase.language != "java") {
    return <div></div>;
  }
  return (
    <div
      onClick={() => {
        if (startEdit) startEdit(testCase);
      }}
      style={{
        padding: 4,
        margin: 8,
        borderRadius: 12,
        border: "solid 1px black",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <h6>{`Test case name (${testCase.language})`}</h6>
      <div style={{ textAlign: "start", fontSize: 12 }}>
        this should be a unique value. For example: testClass1,testFunction1
      </div>
      <textarea
        id="name"
        disabled={!editing || testCase.name}
        value={test.name}
        rows="1"
        name="name"
        onChange={handleInput}
      />

      <h6>Test case description</h6>
      <textarea
        id="description"
        disabled={!editing}
        value={test.description}
        rows="1"
        name="description"
        onChange={handleInput}
      />
      <h6>Test case expected outcome (in text, what is expected to happen)</h6>
      <textarea
        id="expectedOutput"
        disabled={!editing}
        value={test.expectedOutput}
        rows="1"
        name="expectedOutput"
        onChange={handleInput}
      />

      {editing && (
        <div style={{ fontSize: 12 }}>
          <>
            {" "}
            Just write the assertions here (JUnit), It will be added to test
            class before execution.
          </>
          <div style={{ maxWidth: 60, textAlign: "start" }}>
            {`
           public class TestRunnerClass{     
             @Test()
    public void testCaseName() {
      //assertions
    }

}`}
          </div>
        </div>
      )}

      <textarea
        id="test-runner"
        value={test.runner}
        disabled={!editing}
        rows="10"
        name="runner"
        onChange={handleInput}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {editing && <button onClick={() => setTestCase(test)}>Done</button>}
      </div>
    </div>
  );
}
function CTestCaseCard({ testCase, startEdit, editing, setTestCase }) {
  const [test, setTest] = useState(testCase);

  function handleInput(e) {
    setTest({ ...test, [e.target.name]: e.target.value });
  }

  if (testCase.language != "c") {
    return <div></div>;
  }
  return (
    <div
      onClick={() => {
        if (startEdit) startEdit(testCase);
      }}
      style={{
        padding: 4,
        margin: 8,
        borderRadius: 12,
        border: "solid 1px black",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <h6>{`Test case name (${testCase.language})`}</h6>
      <div style={{ textAlign: "start", fontSize: 12 }}>
        this should be a unique value. For example: testClass1,testFunction1
      </div>
      <textarea
        id="name"
        disabled={!editing || testCase.name}
        value={test.name}
        rows="1"
        name="name"
        onChange={handleInput}
      />

      <h6>Test case description</h6>
      <textarea
        id="description"
        disabled={!editing}
        value={test.description}
        rows="1"
        name="description"
        onChange={handleInput}
      />
      <h6>Test case expected outcome (in text, what is expected to happen)</h6>
      <textarea
        id="expectedOutput"
        disabled={!editing}
        value={test.expectedOutput}
        rows="1"
        name="expectedOutput"
        onChange={handleInput}
      />

      {editing && (
        <div style={{ fontSize: 12 }}>
          <>
            {" "}
            Just write the assertions here (ThrowTheSwitch/Unity), it will be
            executed at the end of main function automatically
          </>
          <div style={{ maxWidth: 60, textAlign: "start" }}>
            {`
           
    void testCaseName() {
      //assertions
    }

}`}
          </div>
        </div>
      )}

      <textarea
        id="test-runner"
        value={test.runner}
        disabled={!editing}
        rows="10"
        name="runner"
        onChange={handleInput}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {editing && <button onClick={() => setTestCase(test)}>Done</button>}
      </div>
    </div>
  );
}


function CppTestCaseCard({ testCase, startEdit, editing, setTestCase }) {
  const [test, setTest] = useState(testCase);

  function handleInput(e) {
    setTest({ ...test, [e.target.name]: e.target.value });
  }

  if (testCase.language != "cpp") {
    return <div></div>;
  }
  return (
    <div
      onClick={() => {
        if (startEdit) startEdit(testCase);
      }}
      style={{
        padding: 4,
        margin: 8,
        borderRadius: 12,
        border: "solid 1px black",
        width: "90%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      <h6>{`Test case name (${testCase.language})`}</h6>
      <div style={{ textAlign: "start", fontSize: 12 }}>
        this should be a unique value. For example: testClass1,testFunction1
      </div>
      <textarea
        id="name"
        disabled={!editing || testCase.name}
        value={test.name}
        rows="1"
        name="name"
        onChange={handleInput}
      />

      <h6>Test case description</h6>
      <textarea
        id="description"
        disabled={!editing}
        value={test.description}
        rows="1"
        name="description"
        onChange={handleInput}
      />
      <h6>Test case expected outcome (in text, what is expected to happen)</h6>
      <textarea
        id="expectedOutput"
        disabled={!editing}
        value={test.expectedOutput}
        rows="1"
        name="expectedOutput"
        onChange={handleInput}
      />

      {editing && (
        <div style={{ fontSize: 12 }}>
          <>
            {" "}
            Just write the assertions here (https://github.com/kudaba/simpletest), it will be
            executed at the end of main function automatically
          </>
          <div style={{ maxWidth: 60, textAlign: "start" }}>
            {` 
    DEFINE_TEST(${testCase.name}){
      //your input will be inserted here
    }`}
          </div>
        </div>
      )}

      <textarea
        id="test-runner"
        value={test.runner}
        disabled={!editing}
        rows="10"
        name="runner"
        onChange={handleInput}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {editing && <button onClick={() => setTestCase(test)}>Done</button>}
      </div>
    </div>
  );
}
