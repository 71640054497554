let SERVER_URL = "https://challenges.programiz.com";

if (process.env.REACT_APP_BUILD_ENV) {
  switch (process.env.REACT_APP_BUILD_ENV) {
    case "staging":
      SERVER_URL = "https://staging.challenges.programiz.com";
      break;
    case "pro-staging":
      SERVER_URL = "https://staging.challenges.programiz.pro";
      break;
    case "production":
      SERVER_URL = "https://challenges.programiz.com";
      break;
    case "pro-production":
      SERVER_URL = "https://challenges.programiz.pro";
      break;
    default:
      SERVER_URL = "http://localhost:3000";
      break;
  }
}

export { SERVER_URL };
