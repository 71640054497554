import React, { useState } from "react";
import AdminHome from "./Components/Admin/Challenges";
import PlayerHome from "./Components/Player/Challenges";
import "./App.css";
import { SERVER_URL } from "./consts";

function App() {
  const [mode, setMode] = useState("admin");

  const exportDb = () => {
    window.location.href = `${SERVER_URL}/db_dump`;
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", maxHeight: "100vh" }}
    >
      <div
        style={{
          padding: 10,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          height: 40,
        }}
      >
        <select
          value={mode}
          onChange={e => {
            setMode(e.target.value);
          }}
        >
          <option value="admin">Admin</option>
          <option value="player">Player</option>
        </select>
      </div>
      <button onClick={() => exportDb()}>Export DB</button>
      <div style={{ border: "0.1px solid grey" }}></div>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          overflow: "auto",
        }}
      >
        {mode == "admin" && <AdminHome></AdminHome>}
        {mode == "player" && <PlayerHome></PlayerHome>}
      </div>
    </div>
  );
}

export default App;
